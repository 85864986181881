import { createContext, useContext, useState, ReactNode } from 'react'

type AudioPlayerContextType = {
  currentAudio: HTMLAudioElement | null
  setCurrentAudio: (audio: HTMLAudioElement | null) => void
}

const AudioPlayerContext = createContext<AudioPlayerContextType>({
  currentAudio: null,
  setCurrentAudio: () => {},
})

export function AudioPlayerProvider({ children }: { children: ReactNode }) {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null)

  return <AudioPlayerContext.Provider value={{ currentAudio, setCurrentAudio }}>{children}</AudioPlayerContext.Provider>
}

export const useAudioPlayerContext = () => useContext(AudioPlayerContext)
