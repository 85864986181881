import { Avatar, Tooltip } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import cn from 'classnames'
import { getAvatarUrl } from 'shared/utils/assets.ts'
import { UserOutlined } from '@ant-design/icons'
import s from './s.module.css'
import { TooltipContent } from './TooltipContent/TooltipContent.tsx'
import { useCustomQueryWithParams } from '@/shared/hooks/useCustomQueryWithParams.ts'
import { GET_PROFILE_CONFIG } from '@/shared/api/settings/constants.ts'
import { settingsApi } from '@/shared/api/settings'
import { useAuth } from '@/shared/hooks/useAuth.ts'

export function CustomAvatar() {
  const [isOpen, setIsOpen] = useState(false)
  const { isAuth } = useAuth()
  const { data, isLoading } = useCustomQueryWithParams(GET_PROFILE_CONFIG, settingsApi.getProfileConfig, {
    enabled: isAuth,
  })
  const handleCloseTooltip = useCallback(() => setIsOpen(false), [])
  const avatar = data?.data?.avatar
  const avatarUrl = useMemo(() => getAvatarUrl(avatar), [avatar])

  return (
    <Tooltip
      overlayClassName={s.tooltipContainer}
      trigger="click"
      placement="bottomRight"
      arrow={false}
      title={<TooltipContent onClose={handleCloseTooltip} config={data?.data} isLoading={isLoading} />}
      open={isOpen}
      onOpenChange={setIsOpen}
      overlayInnerStyle={{ border: '1px solid var(--grey-10011)', padding: '20px', textAlign: 'center' }}
    >
      <div className={cn(s.avatarContainer, isOpen && s.avatarOpen)}>
        <Avatar shape="square" size={isOpen ? 24 : 32} src={avatarUrl} icon={<UserOutlined className={s.icon} />} />
      </div>
    </Tooltip>
  )
}
