import { ESettingsNavigation } from '@/pages/Settings/entities/SettingsNavbar/enums.ts'
import { routes } from '@/shared/constants/routes.ts'

export const routesMap: Record<ESettingsNavigation, string> = {
  [ESettingsNavigation.MODERATION]: routes.settings.baseModeration,
  [ESettingsNavigation.CURRENCIES]: routes.settings.baseCurrencies,
  [ESettingsNavigation.DONATION]: routes.settings.baseDonation,
  [ESettingsNavigation.PROFILE]: routes.settings.baseProfile,
  [ESettingsNavigation.GENERAL_SETTINGS]: routes.settings.widgetSettings.baseGeneralSettings,
  [ESettingsNavigation.TEXT]: routes.settings.widgetSettings.baseText,
  [ESettingsNavigation.MEDIA]: routes.settings.widgetSettings.baseMedia,
  [ESettingsNavigation.IMAGE_LIBRARY]: routes.settings.widgetSettings.baseImageLibrary,
}
