import { AxiosResponse } from 'axios'
import { publicClient } from '@/entities/httpClient'
import {
  IChangePasswordConfig,
  ICurrenciesConfig,
  ICurrenciesConfigResponse,
  IDonationConfig,
  IModerationConfig,
  IModerationConfigResponse,
  IProfileConfig,
  IProfileConfigResponse,
  IVoicesAndLanguagesListResponse,
  IWidgetAudio,
  IWidgetConfig,
  IWidgetGeneralSounds,
  IWidgetText,
  IWidgetVideo,
  IBaseCurrency,
  ICurrencyPrecision,
  IPatchWidgetImage,
} from '@/shared/api/settings/types.ts'

const urls = {
  widgetSettings: '/widget/cfg',
  updateWidgetConfig: (widgetId: string) => `/widget/cfg/${widgetId}`,
  updateWidgetAudioConfig: (audioCfgId: string) => `/widget/audio/${audioCfgId}`,
  updateWidgetVideoConfig: (videoCfgId: string) => `/widget/video/${videoCfgId}`,
  updateWidgetTextConfig: (textCfgId: string) => `/widget/text/${textCfgId}`,
  updateWidgetImageConfig: (imageCfgId: string) => `/widget/image/${imageCfgId}`,
  updateDonationConfig: '/donates/webpage',
  getModerationConfig: '/moderation-cfg',
  updateModerationConfig: '/moderation-cfg',
  voicesAndLanguagesList: '/widget/text/voices',
  getTextMessageConfig: '/widget/text',
  changePassword: '/password-reset/change',
  currenciesConfig: '/currencies/settings',
  profileConfig: '/accounts/settings',
  baseCurrencies: '/accounts/base-currencies',
  getCurrencyPrecisions: '/currencies/precisions',
  testNotification: '/notifications/test',
  cryptoCurrencies: '/currencies/crypto',
}

export const settingsApi = {
  async getProfileConfig(): Promise<AxiosResponse<IProfileConfigResponse>> {
    return publicClient.request({
      url: urls.profileConfig,
      method: 'GET',
    })
  },
  async getCurrencyPrecisions(): Promise<AxiosResponse<ICurrencyPrecision[]>> {
    return publicClient.request({
      url: urls.getCurrencyPrecisions,
      method: 'GET',
    })
  },
  async updateProfileConfig(profileConfig: IProfileConfig) {
    return publicClient.request({
      url: urls.profileConfig,
      method: 'PATCH',
      data: profileConfig,
    })
  },
  async getBaseCurrencies(): Promise<AxiosResponse<IBaseCurrency[]>> {
    return publicClient.request({
      url: urls.baseCurrencies,
      method: 'GET',
    })
  },
  async getWidgetSettings(): Promise<AxiosResponse<IWidgetConfig>> {
    return publicClient.request({
      url: urls.widgetSettings,
      method: 'GET',
    })
  },
  async getModerationConfig(): Promise<AxiosResponse<IModerationConfigResponse>> {
    return publicClient.request({
      url: urls.updateModerationConfig,
      method: 'GET',
    })
  },
  async getCurrenciesConfig(): Promise<AxiosResponse<ICurrenciesConfigResponse>> {
    return publicClient.request({
      url: urls.currenciesConfig,
      method: 'GET',
    })
  },
  async getCryptoCurrencies(): Promise<AxiosResponse<string[]>> {
    return publicClient.request({
      url: urls.cryptoCurrencies,
      method: 'GET',
    })
  },
  async updateCurrenciesConfig(currenciesConfig: ICurrenciesConfig) {
    return publicClient.request({
      url: urls.currenciesConfig,
      method: 'PATCH',
      data: currenciesConfig,
    })
  },
  async updateModerationConfig({ moderationConfig }: { moderationConfig: IModerationConfig }) {
    return publicClient.request({
      url: urls.updateModerationConfig,
      method: 'PATCH',
      data: moderationConfig,
    })
  },
  async changePassword(newPasswordConfig: IChangePasswordConfig) {
    return publicClient.request({
      url: urls.changePassword,
      method: 'POST',
      data: newPasswordConfig,
    })
  },
  async updateWidgetConfig({ widgetId, widgetConfig }: { widgetId: string; widgetConfig: IWidgetGeneralSounds }) {
    return publicClient.request({
      url: urls.updateWidgetConfig(widgetId),
      method: 'PATCH',
      data: widgetConfig,
    })
  },
  async updateDonationConfig(donationConfig: IDonationConfig) {
    return publicClient.request({
      url: urls.updateDonationConfig,
      method: 'PATCH',
      data: donationConfig,
    })
  },
  async updateWidgetAudioConfig({
    audioCfgId,
    widgetAudioConfig,
  }: {
    audioCfgId: string
    widgetAudioConfig: IWidgetAudio
  }) {
    return publicClient.request({
      url: urls.updateWidgetAudioConfig(audioCfgId),
      method: 'PATCH',
      data: widgetAudioConfig,
    })
  },
  async updateWidgetVideoConfig({
    videoCfgId,
    widgetVideoConfig,
  }: {
    videoCfgId: string
    widgetVideoConfig: IWidgetVideo
  }) {
    return publicClient.request({
      url: urls.updateWidgetVideoConfig(videoCfgId),
      method: 'PATCH',
      data: widgetVideoConfig,
    })
  },
  async updateWidgetImageConfig({
    imageCfgId,
    widgetImageConfig,
  }: {
    imageCfgId: string
    widgetImageConfig: IPatchWidgetImage
  }) {
    return publicClient.request({
      url: urls.updateWidgetImageConfig(imageCfgId),
      method: 'PATCH',
      data: widgetImageConfig,
    })
  },
  async updateWidgetTextConfig({ textCfgId, widgetTextConfig }: { textCfgId: string; widgetTextConfig: IWidgetText }) {
    return publicClient.request({
      url: urls.updateWidgetTextConfig(textCfgId),
      method: 'PATCH',
      data: widgetTextConfig,
    })
  },
  async getLanguagesAndVoicesList(): Promise<AxiosResponse<IVoicesAndLanguagesListResponse>> {
    return publicClient.request({
      url: urls.voicesAndLanguagesList,
      method: 'GET',
    })
  },
  async postTestNotification(): Promise<AxiosResponse<void>> {
    return publicClient.request({
      url: urls.testNotification,
      method: 'POST',
    })
  },
}
