export enum LocalStorageKey {
  Locale = 'locale',
}

export type LocalStorageKeyMap = {
  [LocalStorageKey.Locale]: string | null
}

export interface IStorage<TValue> {
  get(): TValue
  set(value: TValue): void
  remove(): void
  addChangeListener(listener: (value: TValue) => void): void
  removeChangeListener(listener: (value: TValue) => void): void
}
