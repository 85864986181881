import * as Sentry from '@sentry/react'
import { useLocation, useNavigationType, matchRoutes, createRoutesFromChildren } from 'react-router-dom'
import { useEffect } from 'react'

export function initSentry() {
  const dsn = import.meta.env.VITE_SENTRY_DSN
  if (!dsn) {
    return
  }
  Sentry.init({
    dsn,
    integrations: [
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [0, [400, 599]],
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        matchRoutes,
        createRoutesFromChildren,
      }),
    ],
    sendDefaultPii: true,
  })
}
