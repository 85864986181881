import { Layout } from 'antd'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import s from './s.module.css'
import SpinLoadingOutlined from '@/shared/components/SpinLoadingOutlined'

function ObsLayout() {
  return (
    <Layout className={s.container}>
      <Suspense fallback={<SpinLoadingOutlined />}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}

export default ObsLayout
