import { MenuProps } from 'antd'
import classNames from 'classnames'
import { IntlShape } from 'react-intl'
import s from './SettingsNavbar.module.css'
import ToyIcon from './icons/toy-icon.svg?react'
import CurrenciesIcon from './icons/currencies-icon.svg?react'
import WindowIcon from './icons/window-icon.svg?react'
import AccountIcon from './icons/account-icon.svg?react'
import AttentionIcon from './icons/attention-icon.svg?react'
import { lang } from './lang.ts'
import ArrowRightIcon from '@/shared/icons/arrow-right-icon.svg?react'

type MenuItem = Required<MenuProps>['items'][number]

export const getSubMenuItems = (intl: IntlShape) => [
  {
    key: 'general_settings',
    label: intl.formatMessage(lang.general),
  },
  {
    key: 'text',
    label: intl.formatMessage(lang.text),
  },
  {
    key: 'media',
    label: intl.formatMessage(lang.media),
  },
  {
    key: 'image_library',
    label: intl.formatMessage(lang.imagesLibrary),
  },
]

export const getSettingsNavbarItems = (intl: IntlShape): MenuItem[] => [
  {
    key: 'moderation',
    label: intl.formatMessage(lang.moderation),
    icon: <AttentionIcon className={s.itemMenuIcon} />,
  },
  {
    key: 'widgets',
    label: intl.formatMessage(lang.widgets),
    icon: <ToyIcon className={s.itemMenuIcon} />,
    expandIcon: renderInfo => {
      return <ArrowRightIcon className={classNames(s.arrowIcon, !renderInfo.isOpen && s.arrowIconRotate)} />
    },
    type: 'submenu',
    children: getSubMenuItems(intl),
  },
  {
    key: 'currencies',
    label: intl.formatMessage(lang.currencies),
    icon: <CurrenciesIcon className={s.itemMenuIcon} />,
  },
  {
    key: 'donation',
    label: intl.formatMessage(lang.donation),
    icon: <WindowIcon className={s.itemMenuIcon} />,
  },
  {
    key: 'profile',
    label: intl.formatMessage(lang.profile),
    icon: <AccountIcon className={s.itemMenuIcon} />,
  },
]
