import './reset.css'
import './App.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import { lazy } from 'react'
import { ToastContainer } from 'react-toastify'
import { useToastScroll } from 'shared/hooks/useToastScroll.ts'
import { routes } from '@/shared/constants/routes.ts'
import AuthLayout from '@/layouts/Auth'
import DashboardLayout from '@/layouts/Dashboard'
import ReferralLayout from '@/layouts/Referral'
import TransferLayout from '@/layouts/Transfer'
import OnboardingLayout from '@/layouts/Onboarding'
import SettingsLayout from '@/layouts/Settings'
import NotificationsLayout from '@/layouts/Notifications'
import ObsLayout from '@/layouts/Obs'
import NotFoundLayout from '@/layouts/NotFound'
import { SkipAuth } from '@/shared/components/RouteGuard/SkipAuth/SkipAuth.tsx'
import { RequireOnboard } from '@/shared/components/RouteGuard/RequireOnboard/RequireOnboard.tsx'
import { RequireAuth } from '@/shared/components/RouteGuard/RequireAuth/RequireAuth.tsx'
import ScrollToTop from '@/shared/components/ScrollToTop'

/**
 * Auth
 */
const SignIn = lazy(() => import('@/pages/Auth/SignIn'))
const SignUp = lazy(() => import('@/pages/Auth/SignUp'))
const Verification = lazy(() => import('@/pages/Auth/Verification'))
const TwoFa = lazy(() => import('@/pages/Auth/TwoFa'))
const CreateNewPassword = lazy(() => import('@/pages/Auth/CreateNewPassword'))
const ResetPassword = lazy(() => import('@/pages/Auth/ResetPassword'))

/**
 * Onboard
 */
const Wallet = lazy(() => import('@/pages/Onboarding/Wallet'))
const Username = lazy(() => import('@/pages/Onboarding/Username/Username.tsx'))
const Donation = lazy(() => import('@/pages/Onboarding/Donation'))
const Obs = lazy(() => import('@/pages/Onboarding/Obs'))

/**
 * Notifications
 */
const Notifications = lazy(() => import('@/pages/Notifications'))

/**
 * Settings
 */
const Moderation = lazy(() => import('@/pages/Settings/entities/Moderation'))
const Currencies = lazy(() => import('@/pages/Settings/entities/Currencies'))
const Profile = lazy(() => import('@/pages/Settings/entities/Profile'))
const SettingsDonation = lazy(() => import('@/pages/Settings/entities/Donation'))
const GeneralSettingsWidget = lazy(() => import('@/pages/Settings/entities/Widgets/GeneralSettingsWidget'))
const TextWidget = lazy(() => import('@/pages/Settings/entities/Widgets/TextWidget'))
const MediaWidget = lazy(() => import('@/pages/Settings/entities/Widgets/MediaWidget'))
const ImageLibrary = lazy(() => import('@/pages/Settings/entities/ImageLibrary'))

/**
 * Dashboard
 */
const Dashboard = lazy(() => import('@/pages/Dashboard'))

/**
 * Referral
 */
const Referral = lazy(() => import('@/pages/Referral'))

/**
 * Transfer
 */
const Transfer = lazy(() => import('@/pages/Transfer/Transfer'))
const Download = lazy(() => import('@/pages/Transfer/Download'))

const ObsLazy = lazy(() => import('@/pages/Obs'))
const NotFound = lazy(() => import('@/pages/NotFound/NotFound.tsx'))

function App() {
  useToastScroll(100)

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<RequireAuth />}>
          <Route element={<RequireOnboard />}>
            <Route element={<DashboardLayout />}>
              <Route path={routes.dashboard.base} element={<Dashboard />} />
            </Route>
            <Route element={<TransferLayout />}>
              <Route path={routes.transfer.baseSend} element={<Transfer />} />
              <Route path={routes.transfer.baseDownload} element={<Download />} />
            </Route>
            <Route element={<SettingsLayout />}>
              <Route path={routes.settings.baseModeration} element={<Moderation />} />
              <Route path={routes.settings.baseDonation} element={<SettingsDonation />} />
              <Route path={routes.settings.baseCurrencies} element={<Currencies />} />
              <Route path={routes.settings.baseProfile} element={<Profile />} />
              <Route path={routes.settings.widgetSettings.baseGeneralSettings} element={<GeneralSettingsWidget />} />
              <Route path={routes.settings.widgetSettings.generalSettings} element={<GeneralSettingsWidget />} />
              <Route path={routes.settings.widgetSettings.baseText} element={<TextWidget />} />
              <Route path={routes.settings.widgetSettings.baseMedia} element={<MediaWidget />} />
              <Route path={routes.settings.widgetSettings.baseImageLibrary} element={<ImageLibrary />} />
            </Route>
            <Route element={<NotificationsLayout />}>
              <Route path={routes.notifications.base} element={<Notifications />} />
            </Route>
            <Route element={<ReferralLayout />}>
              <Route path={routes.referral.base} element={<Referral />} />
            </Route>
          </Route>
          <Route element={<OnboardingLayout />}>
            <Route path={routes.onboarding.baseWallet} element={<Wallet />} />
            <Route path={routes.onboarding.baseUsername} element={<Username />} />
            <Route element={<RequireOnboard />}>
              <Route path={routes.onboarding.baseDonation} element={<Donation />} />
              <Route path={routes.onboarding.baseObs} element={<Obs />} />
            </Route>
          </Route>
        </Route>
        <Route element={<SkipAuth />}>
          <Route element={<AuthLayout />}>
            <Route path={routes.auth.signIn} element={<SignIn />} />
            <Route path={routes.auth.signUp} element={<SignUp />} />
            <Route path={routes.auth.signInVerification} element={<TwoFa />} />
            <Route path={routes.auth.signUpVerification} element={<Verification />} />
            <Route path={routes.auth.resetPassword} element={<ResetPassword />} />
            <Route path={routes.auth.creatNewPassword} element={<CreateNewPassword />} />
          </Route>
        </Route>
        <Route path={routes.obs.base} element={<ObsLayout />}>
          <Route path="*" element={<ObsLazy />} />
          <Route index element={<Navigate to={routes.obs.baseAlerts} replace />} />
        </Route>
        <Route index element={<Navigate to={routes.dashboard.base} replace />} />
        <Route element={<NotFoundLayout />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ToastContainer
        theme="dark"
        containerId="service"
        position="top-right"
        closeOnClick={false}
        pauseOnHover
        pauseOnFocusLoss
      />
    </>
  )
}

export default App
