import { ImageAssetResponse, ImageSource } from '@/types/assets'

export type ImageArg = Pick<ImageAssetResponse, 'url' | 'thumbnails'>

export const getSrcSet = (thumbnails?: ImageAssetResponse['thumbnails']) => {
  if (!thumbnails) {
    return ''
  }

  return thumbnails
    .map(({ url, width }) => {
      return `${url} ${width}w`
    })
    .join(', ')
}

export const getImgSizes = (asset: Pick<ImageAssetResponse, 'url' | 'thumbnails'>) => {
  const sizes: ImageSource = { original: asset.url }

  if (asset.thumbnails) {
    const [s, m, l] = asset.thumbnails?.sort((a, b) => Number(a.width) - Number(b.width)) ?? []
    sizes.s = s
    sizes.m = m
    sizes.l = l
  }

  return sizes
}

export const getAvatarUrl = (avatar?: Pick<ImageAssetResponse, 'url' | 'thumbnails'>) => {
  if (!avatar) {
    return undefined
  }

  const sizes = getImgSizes(avatar)
  return sizes.s?.url || sizes.m?.url || sizes.l?.url || sizes.original || undefined
}
