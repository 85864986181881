import { Menu } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useEffect, useMemo, useState } from 'react'
import s from './SettingsNavbar.module.css'
import { SelectInfo } from './types.ts'
import { ESettingsNavigation } from './enums.ts'
import { getSettingsNavbarItems, getSubMenuItems } from './Settings.constants.tsx'
import { routesMap } from './routesMap.ts'

const getKeyFromPath = (path: string) => {
  return Object.entries(routesMap).filter(([, value]) => value === path)?.[0]?.[0]
}

const getDefaultSelectedKeys = (path: string) => {
  const key = getKeyFromPath(path)

  if (key) {
    return [key]
  }
}

const getDefaultOpenKey = (selectedKey?: string[], subMenuItems?: { key: string }[]) => {
  if (subMenuItems?.findIndex(({ key }) => key === selectedKey?.[0]) !== -1) {
    return 'widgets'
  }
}

export function SettingsNavbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const defaultSelectedKey = getDefaultSelectedKeys(location.pathname)
  const intl = useIntl()
  const settingsNavbarItems = useMemo(() => getSettingsNavbarItems(intl), [intl])
  const subMenuItems = useMemo(() => getSubMenuItems(intl), [intl])
  const defaultOpenKey = useMemo(
    () => getDefaultOpenKey(defaultSelectedKey, subMenuItems),
    [defaultSelectedKey, subMenuItems]
  )
  const [selectedMenuItem, setSelectedMenuItem] = useState(defaultOpenKey)

  const handleSelect = (selectedItemMenu: SelectInfo) => {
    const key = selectedItemMenu.key as ESettingsNavigation
    const route = routesMap[key]
    setSelectedMenuItem(key)
    navigate(route)
  }

  useEffect(() => {
    const key = getKeyFromPath(location.pathname)
    setSelectedMenuItem(key)
  }, [location.pathname])

  return (
    <div className={s.container}>
      <Menu
        inlineIndent={16}
        items={settingsNavbarItems}
        mode="inline"
        onSelect={handleSelect}
        defaultSelectedKeys={defaultSelectedKey}
        defaultOpenKeys={defaultOpenKey ? [defaultOpenKey] : []}
        selectedKeys={selectedMenuItem ? [selectedMenuItem] : []}
      />
    </div>
  )
}
