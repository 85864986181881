import { createContext } from 'react'

interface LanguageContext {
  locale: string
  handleLocaleChange: (language: string) => void
}

const defaultState = {
  locale: 'en',
  handleLocaleChange: () => {},
}

export const LocaleContext = createContext<LanguageContext>(defaultState)
