import { useCallback } from 'react'
import { Avatar, Button, Flex, Skeleton } from 'antd'
import Text from 'shared/components/typography/Text'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { getAvatarUrl } from 'shared/utils/assets'
import { UserOutlined } from '@ant-design/icons'
import s from './s.module.css'
import SignoutIcon from './signout.svg?react'
import { lang } from './lang'
import { routes } from '@/shared/constants/routes'
import { IProfileConfigResponse } from '@/shared/api/settings/types'
import { logout } from '@/shared/utils/auth/logout.ts'

interface Props {
  onClose: () => void
  config?: IProfileConfigResponse
  isLoading?: boolean
}

export function TooltipContent({ onClose, config, isLoading }: Props) {
  const { nickname, email, avatar } = config || {}
  const avatarUrl = getAvatarUrl(avatar)

  const navigate = useNavigate()
  const navigateToProfile = useCallback(() => {
    navigate(routes.settings.baseProfile)
    onClose()
  }, [navigate, onClose])

  return (
    <Flex vertical align="center" gap={12} className={s.maxWidth}>
      {isLoading ? (
        <Flex vertical className={s.maxWidth} gap={12} align="center">
          <Skeleton.Avatar active size={56} shape="square" />
          <Flex vertical className={s.maxWidth} align="center">
            <Skeleton.Input className={s.skeleton} />
            <Skeleton.Input className={s.skeleton} />
          </Flex>
        </Flex>
      ) : (
        <Flex vertical gap={8} align="center" className={s.maxWidth}>
          <Avatar shape="square" src={avatarUrl} size={56} icon={<UserOutlined />} />
          <Flex vertical align="center" className={s.maxWidth}>
            <Text ellipsis type="primary" fontType="title" fontSize={16}>
              {nickname}
            </Text>
            <Text ellipsis fontType="body" fontSize={14}>
              {email}
            </Text>
          </Flex>
        </Flex>
      )}
      <Flex vertical gap={8} className={s.maxWidth}>
        <Button block type="primary" onClick={navigateToProfile}>
          <FormattedMessage {...lang.profileButton} />
        </Button>
        <Button block onClick={() => logout()} type="primary" ghost className={s.signoutButton} icon={<SignoutIcon />}>
          <FormattedMessage {...lang.signOutButton} />
        </Button>
      </Flex>
    </Flex>
  )
}
