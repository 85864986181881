import { IStorage } from '@/entities/localStorage/types.ts'

export class StorageService<TValue> implements IStorage<TValue> {
  protected readonly listeners: Set<(value: TValue) => void> = new Set()
  protected readonly key: string

  private readonly defaultValue: TValue
  private readonly storage: Storage

  constructor(key: string, defaultValue: TValue, storage: Storage) {
    this.key = key
    this.defaultValue = defaultValue
    this.storage = storage
  }

  public get(): TValue {
    try {
      const value = this.storage.getItem(this.key)
      return value != null ? (JSON.parse(value) as TValue) : this.defaultValue
    } catch (error) {
      console.error('Error getting value from storages:', error)
      return this.defaultValue
    }
  }

  public set(value: TValue) {
    try {
      this.storage.setItem(this.key, JSON.stringify(value))
      this.dispatchChangeEvent()
    } catch (error) {
      console.error('Error setting value in storages:', error)
    }
  }

  public remove() {
    try {
      this.storage.removeItem(this.key)
      this.dispatchChangeEvent()
    } catch (error) {
      console.error('Error removing value from storages:', error)
    }
  }

  public addChangeListener(listener: (value: TValue) => void) {
    this.listeners.add(listener)
  }

  public removeChangeListener(listener: (value: TValue) => void) {
    this.listeners.delete(listener)
  }

  protected dispatchChangeEvent() {
    const value = this.get()
    this.listeners.forEach(listener => listener(value))
  }
}
