import classNames from 'classnames'
import { useMemo } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { Link, Location, useLocation } from 'react-router-dom'
import s from './s.module.css'
import { lang } from './lang.ts'
import { routes } from '@/shared/constants/routes.ts'

const getStepsList = (intl: IntlShape) => [
  intl.formatMessage(lang.first),
  intl.formatMessage(lang.second),
  intl.formatMessage(lang.third),
  intl.formatMessage(lang.fourth),
]

const stepsLinks = [
  routes.onboarding.baseWallet,
  routes.onboarding.baseUsername,
  routes.onboarding.baseDonation,
  routes.onboarding.baseObs,
]

enum Steps {
  'wallet',
  'username',
  'donation',
  'obs',
}

const getStep = (location: Location) => {
  if (location.pathname?.indexOf(routes.onboarding.baseUsername) > -1) {
    return Steps.username
  }
  if (location.pathname?.indexOf(routes.onboarding.baseDonation) > -1) {
    return Steps.donation
  }
  if (location.pathname?.indexOf(routes.onboarding.baseObs) > -1) {
    return Steps.obs
  }

  return Steps.wallet
}

const stepClasses = (index: number, currentStep: number) =>
  classNames(s.step, {
    [s.active]: index === currentStep,
    [s.complete]: index < currentStep,
  })

function Stepper() {
  const location = useLocation()
  const currentStep: Steps = useMemo(() => getStep(location), [location])
  const intl = useIntl()

  return (
    <div className={s.stepper}>
      <div className={s.progress}>
        {getStepsList(intl).map((step, index) => (
          <div key={index} className={stepClasses(index, currentStep)}>
            {index < currentStep ? (
              <Link replace to={stepsLinks[index]} className={s.link}>
                <div className={s.circle} />
                <div className={s.label}>{step}</div>
              </Link>
            ) : (
              <div className={s.link}>
                <div className={s.circle}>{index + 1}</div>
                <div className={s.label}>{step}</div>
              </div>
            )}
            <div className={s.line} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Stepper
