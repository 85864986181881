import { defineMessages } from 'react-intl'

export const lang = defineMessages({
  first: {
    id: 'stepper.wallet_app',
    defaultMessage: 'Wallet App',
  },
  second: {
    id: 'stepper.username',
    defaultMessage: 'Public Username',
  },
  third: {
    id: 'stepper.donation_page',
    defaultMessage: 'Donation Page',
  },
  fourth: {
    id: 'stepper.obs_widget',
    defaultMessage: 'OBS Widget',
  },
})
