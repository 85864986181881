import { FormattedMessage } from 'react-intl'
import Link from 'shared/components/typography/Link'
import Text from 'shared/components/typography/Text'
import { SUPPORT_EMAIL, SUPPORT_HREF } from 'shared/constants/links.ts'
import s from './Footer.module.css'
import { lang } from '@/shared/components/Footer/lang.ts'

function Footer() {
  return (
    <footer className={s.footer}>
      <Link
        fontSize={14}
        target="_blank"
        rel="noopener noreferrer"
        fontType="body"
        href={SUPPORT_HREF}
        type="secondary"
      >
        {SUPPORT_EMAIL}
      </Link>
      <Text fontSize={14} fontType="body" type="tertiary" textAlign="center">
        <FormattedMessage {...lang.copyright} />
      </Text>
    </footer>
  )
}

export default Footer
