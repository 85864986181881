import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { routes } from '@/shared/constants/routes.ts'
import { useAuth } from '@/shared/hooks/useAuth.ts'
import { setRedirectPathname } from '@/shared/utils/auth/redirectPathname.ts'

function Guard() {
  const { pathname } = useLocation()

  useEffect(() => {
    setRedirectPathname(pathname)
  }, [pathname])

  return <Navigate to={routes.auth.signIn} replace />
}

export function RequireAuth() {
  const { isAuth } = useAuth()

  return isAuth ? <Outlet /> : <Guard />
}
