import { Select, ConfigProvider, Flex } from 'antd'
import { themeConfig } from './theme.ts'
import Global from './global.svg?react'
import { useLanguageSelect } from '@/shared/hooks/useLanguageSelect.ts'

function LanguagesSelect() {
  const { setLang, isMoreThanOneLang, selectedLocale, options, isLoading } = useLanguageSelect()

  return (
    <ConfigProvider theme={themeConfig}>
      <Select
        style={{ pointerEvents: isMoreThanOneLang ? 'auto' : 'none' }}
        notFoundContent={null}
        onSelect={setLang as any}
        value={selectedLocale}
        disabled={isLoading}
        options={options}
        popupMatchSelectWidth={false}
        suffixIcon={isMoreThanOneLang || isLoading ? undefined : null}
        labelRender={option => (
          <Flex style={{ fontWeight: '500' }} align="center" gap={4}>
            <Global />
            {option?.label}
          </Flex>
        )}
        listHeight={180}
      />
    </ConfigProvider>
  )
}

export default LanguagesSelect
