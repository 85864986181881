import { RefObject, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type Props = {
  element?: RefObject<HTMLElement>
  updateOn?: unknown
}

/**
 * Scroll to top
 */
function ScrollToTop({ element, updateOn }: Props) {
  const { pathname } = useLocation()
  const currentEl = element?.current

  useEffect(() => {
    const el = element ? currentEl : window
    el?.scrollTo(0, 0)
  }, [pathname, currentEl, element, updateOn])

  return null
}

export default ScrollToTop
