import { ReactNode, useEffect, useState } from 'react'
import { LocaleContext } from './LocaleContext.tsx'
import { LocalStorageKey, useLocalStorage } from '@/entities/localStorage'

export function LocaleProvider({ children }: { children: ReactNode }) {
  const storage = useLocalStorage(LocalStorageKey.Locale)

  const initialLocale = storage.get() || navigator.language.split(/[-_]/)[0] || 'en'

  const [locale, setLocale] = useState<string>(initialLocale)

  useEffect(() => {
    storage.set(locale)
  }, [locale, storage])

  const handleLocaleChange = (newLocale: string) => {
    setLocale(newLocale)
  }

  return <LocaleContext.Provider value={{ locale, handleLocaleChange }}>{children}</LocaleContext.Provider>
}
