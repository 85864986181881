import { IS_AUTH, IS_ONBOARDED } from '@/shared/constants'
import { routes } from '@/shared/constants/routes.ts'
import { setRedirectPathname } from '@/shared/utils/auth/redirectPathname.ts'

export const logout = (setRedirect?: boolean) => {
  localStorage.removeItem(IS_AUTH)
  localStorage.removeItem(IS_ONBOARDED)
  if (setRedirect) {
    setRedirectPathname(window.location.pathname)
  }
  window.location.href = routes.auth.signIn
}
