import { defineMessages } from 'react-intl'

export const lang = defineMessages({
  moderation: {
    id: 'settings.menu.navbar_moderation',
    defaultMessage: 'Moderation',
  },
  widgets: {
    id: 'settings.menu.navbar_widgets',
    defaultMessage: 'Widgets',
  },
  currencies: {
    id: 'settings.menu.navbar_currencies',
    defaultMessage: 'Currencies',
  },
  donation: {
    id: 'settings.menu.navbar_donation',
    defaultMessage: 'Donation Page',
  },
  profile: {
    id: 'settings.menu.navbar_profile',
    defaultMessage: 'Profile',
  },
  general: {
    id: 'settings.menu.navbar_widgets_general_tab',
    defaultMessage: 'General',
  },
  text: {
    id: 'settings.menu.navbar_widgets_text',
    defaultMessage: 'Text',
  },
  media: {
    id: 'settings.menu.navbar_widgets_media',
    defaultMessage: 'Media',
  },
  imagesLibrary: {
    id: 'settings.menu.navbar_widgets_images_library',
    defaultMessage: 'Images Library',
  },
})
