export enum ESettingsNavigation {
  MODERATION = 'moderation',
  CURRENCIES = 'currencies',
  DONATION = 'donation',
  PROFILE = 'profile',
  GENERAL_SETTINGS = 'general_settings',
  TEXT = 'text',
  MEDIA = 'media',
  IMAGE_LIBRARY = 'image_library',
}
