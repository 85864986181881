import { ReactNode } from 'react'
import { Property } from 'csstype'
import cn from 'classnames'
import { useFontName } from '../hooks.ts'
import { FontProps } from '../type.ts'
import fonts from '../fonts.module.css'
import s from './s.module.css'

interface Props extends FontProps {
  children: ReactNode
  type?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  color?: string
  className?: string
  fontFamily?: string
  textAlign?: Property.TextAlign
  underline?: boolean
  ellipsis?: boolean
  cursor?: string
  display?: string
  onClick?: () => void
}

function Text({
  children,
  type = 'secondary',
  color,
  underline,
  fontType,
  fontSize,
  textAlign,
  fontFamily,
  cursor,
  ellipsis,
  display,
  onClick,
  className,
  ...rest
}: Props) {
  const font = useFontName({ fontType, fontSize })
  return (
    <span
      style={{ color, textAlign, fontFamily, cursor, display }}
      className={cn(s[type], fonts[font], underline && fonts.underline, ellipsis && fonts.ellipsis, className)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </span>
  )
}

export default Text
