import { LocalStorageKey, LocalStorageKeyMap, IStorage } from './types'
import { LocalStorageService } from '@/entities/services/LocalStorageService.ts'

export const defaultValueMap: LocalStorageKeyMap = {
  [LocalStorageKey.Locale]: null,
}

/**
 * Класс Одиночка предоставляет метод `get`, который позволяет по ключу `StorageKey` получить
 * доступ к уникальному экземпляру `StorageService`.
 */
export class LocalStorageContainer {
  private static instances = new Map<LocalStorageKey, unknown>()

  /**
   * Конструктор Одиночки всегда должен быть скрытым, чтобы предотвратить
   * создание объекта через оператор new.
   */
  private constructor() {}

  /**
   * Статический метод, управляющий доступом к экземплярам `StorageService`.
   *
   * Эта реализация позволяет вам расширять класс Одиночки, сохраняя повсюду
   * только один экземпляр каждого подкласса.
   */
  public static get<Key extends LocalStorageKey>(key: Key): IStorage<LocalStorageKeyMap[Key]> {
    if (!LocalStorageContainer.instances.has(key)) {
      LocalStorageContainer.instances.set(key, new LocalStorageService(key, defaultValueMap[key]))
    }

    return LocalStorageContainer.instances.get(key) as IStorage<LocalStorageKeyMap[Key]>
  }
}
