import { Layout, Flex } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { routes } from 'src/shared/constants/routes.ts'
import s from './s.module.css'
import Footer from '@/shared/components/Footer'
import Stepper from '@/pages/Onboarding/components/Stepper'
import Header from '@/shared/components/Header'
import SpinLoadingOutlined from '@/shared/components/SpinLoadingOutlined'

function OnboardingLayout() {
  return (
    <Layout className={s.container}>
      <Header logoLink={routes.onboarding.baseWallet} showLanguages showLogout />
      <Layout>
        <>
          <Flex justify="center" className={s.stepper}>
            <Stepper />
          </Flex>
          <Content className={s.content}>
            <Suspense fallback={<SpinLoadingOutlined />}>
              <Outlet />
            </Suspense>
          </Content>
        </>
      </Layout>
      <Footer />
    </Layout>
  )
}

export default OnboardingLayout
