import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import s from './s.module.css'
import Footer from '@/shared/components/Footer'
import Header from '@/shared/components/Header'
import SpinLoadingOutlined from '@/shared/components/SpinLoadingOutlined'

function NotificationsLayout() {
  return (
    <Layout className={s.container}>
      <Header showLanguages />
      <Layout className={s.contentWrapper}>
        <Content className={s.content}>
          <Suspense fallback={<SpinLoadingOutlined />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
      <Footer />
    </Layout>
  )
}

export default NotificationsLayout
