import React from 'react'

import { LocalStorageContainer } from './localStorageContainer.ts'
import { IStorage, LocalStorageKey, LocalStorageKeyMap } from './types'

export const useLocalStorage = <Key extends LocalStorageKey>(key: Key): IStorage<LocalStorageKeyMap[Key]> => {
  const storage = React.useRef(LocalStorageContainer.get(key))

  return storage.current
}
