import { Flex } from 'antd'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'
import { useMatch } from 'react-router'
import Link from 'shared/components/typography/Link'
import s from '../s.module.css'
import Icon from './icon.svg?react'
import { lang } from './lang.ts'
import { routes } from '@/shared/constants/routes.ts'

function Dashboard() {
  const match = useMatch(`${routes.dashboard.base}/*`)

  return (
    <Flex gap={4} justify="center" align="center">
      <Link
        fontType="title"
        type="secondary"
        fontSize={4}
        underline={false}
        className={match ? s.active : undefined}
        to={match ? '#' : routes.dashboard.base}
      >
        <Icon className={cn(s.icon, !!match && s.active)} />
        <FormattedMessage {...lang.dashboard} />
      </Link>
    </Flex>
  )
}

export default Dashboard
