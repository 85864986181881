import { Flex } from 'antd'
import cn from 'classnames'
import Link from 'shared/components/typography/Link'
import s from './s.module.css'
import LanguagesSelect from './LanguagesSelect'
import Logout from './Logout'
import Settings from './Settings'
import Dashboard from './Dashboard'
import Referral from './Referral'
import LogoIcon from './logo.svg?react'
import { routes } from '@/shared/constants/routes.ts'
import { CustomAvatar } from '@/shared/components/Header/Avatar/Avatar.tsx'

interface Props {
  showSettings?: boolean
  showLanguages?: boolean
  showLogout?: boolean
  showDashboard?: boolean
  showReferral?: boolean
  showAvatar?: boolean
  className?: string
  logoLink?: string
}

function Header({
  showSettings,
  showLanguages,
  showReferral,
  showLogout,
  showDashboard,
  showAvatar,
  logoLink,
  className,
}: Props) {
  return (
    <header className={cn(s.header, className)}>
      <Link underline={false} to={logoLink ?? routes.dashboard.base}>
        <LogoIcon />
      </Link>

      <Flex gap={32} align="center">
        <Flex gap={16} align="center">
          {showReferral && <Referral />}
          {showDashboard && <Dashboard />}
          {showSettings && <Settings />}
          {showLanguages && <LanguagesSelect />}
          {showLogout && <Logout />}
        </Flex>
        {showAvatar && <CustomAvatar />}
      </Flex>
    </header>
  )
}

export default Header
