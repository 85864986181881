import { theme, ThemeConfig } from 'antd'

export const themeConfig: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  components: {
    Select: {
      colorText: 'var(--grey-100)',
      colorBgBase: 'transparent',
      colorBgContainer: 'transparent',
      colorBorder: 'transparent',
      colorPrimaryHover: 'var(--grey-600)',
      colorPrimary: 'transparent',
      boxShadow: 'transparent',
      colorTextPlaceholder: 'var(--grey-300)',
      showArrowPaddingInlineEnd: 20,
      controlOutline: 'var(--accent-light-outline)',
    },
  },
}
