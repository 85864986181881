import { ReactNode } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import cn from 'classnames'
import fonts from '../fonts.module.css'
import { FontProps } from '../type.ts'
import { useFontName } from '../hooks.ts'
import s from './s.module.css'

interface Props extends Omit<LinkProps, 'to'>, FontProps {
  children: ReactNode
  className?: string
  color?: string
  type?: 'primary' | 'secondary' | 'tertiary'
  to?: string
  href?: string
  blank?: boolean
  underline?: boolean
}

function Link({
  children,
  color,
  type = 'primary',
  fontType,
  fontSize,
  to,
  href,
  blank,
  className,
  underline = true,
  ...rest
}: Props) {
  const font = useFontName({ fontType, fontSize })

  if (to) {
    return (
      <RouterLink to={to} className={cn(s.link, s[type], fonts[font], underline && s.underline, className)} {...rest}>
        {children}
      </RouterLink>
    )
  }

  return (
    <a
      href={href}
      rel="noopener noreferrer"
      target={blank ? '_blank' : undefined}
      className={cn(s.link, s[type], fonts[font], underline && s.underline, className)}
      style={{ color }}
      {...rest}
    >
      {children}
    </a>
  )
}

export default Link
