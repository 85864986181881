import { Flex } from 'antd'
import { FormattedMessage } from 'react-intl'
import Link from 'shared/components/typography/Link'
import Icon from './icon.svg?react'
import { lang } from './lang.ts'
import s from './s.module.css'
import { logout } from '@/shared/utils/auth/logout.ts'

function Logout() {
  return (
    <Flex gap={4} justify="center" align="center" className={s.container}>
      <Link
        underline={false}
        onClick={e => {
          e.preventDefault()
          logout()
        }}
        fontType="title"
        fontSize={4}
        tabIndex={0}
      >
        <Icon />
        <FormattedMessage {...lang.logout} />
      </Link>
    </Flex>
  )
}

export default Logout
