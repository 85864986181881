export const routes = {
  home: '/',
  obs: {
    base: '/obs',
    baseMedia: '/obs/media',
    baseAlerts: '/obs/alerts',
    media: '/media',
    alerts: '/alerts',
  },
  auth: {
    signUp: '/sign-up',
    signInVerification: '/sign-in-verification-code',
    signUpVerification: '/sign-up-verification-code',
    signIn: '/sign-in',
    resetPassword: '/reset-password',
    creatNewPassword: '/create-new-password',
  },
  onboarding: {
    baseWallet: '/onboarding/wallet',
    baseUsername: '/onboarding/username',
    baseDonation: '/onboarding/donation',
    baseObs: '/onboarding/obs',
    wallet: '/wallet',
    username: '/username',
    donation: '/donation',
    obs: '/obs',
  },
  dashboard: {
    base: '/dashboard',
    confirmation2fa: '/dashboard/transfer/confirmation-2fa',
  },
  referral: {
    base: '/referral',
  },
  transfer: {
    baseSend: '/transfer/send',
    send: '/send',
    download: '/download',
    baseDownload: '/transfer/download',
  },
  settings: {
    base: '/settings',
    baseModeration: '/settings/moderation',
    baseCurrencies: '/settings/currencies',
    baseDonation: '/settings/donation',
    moderation: '/moderation',
    currencies: '/currencies',
    donation: '/donation',
    widgetSettings: {
      baseGeneralSettings: '/settings/widgets/general-settings',
      baseText: '/settings/widgets/text',
      baseMedia: '/settings/widgets/media',
      generalSettings: '/widgets/general-settings',
      baseImageLibrary: '/settings/widgets/image-library',
      text: '/widgets/text',
      media: '/widgets/media',
      imageLibrary: '/widgets/image-library',
    },
    baseProfile: '/settings/profile',
    profile: '/profile',
  },
  notifications: {
    base: '/notifications',
  },
} as const
