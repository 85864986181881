import { useMemo } from 'react'
import { useLocale } from '@/shared/contexts/LocaleContext'
import { useCustomQueryWithParams } from '@/shared/hooks/useCustomQueryWithParams.ts'
import { getSupportLocales } from '@/shared/api/constants.ts'
import { sharedApi, SupportedLocales } from '@/shared/api'
import { getSelectedLocale } from '@/shared/utils/getSelectedLocale.ts'

const defaultValue = { label: 'English', value: 'en' }

interface UseLanguageSelect {
  setLang: (value: string) => void
  isLoading: boolean
  selectedLocale: { label: string; value: string }
  isMoreThanOneLang: boolean
  options: SupportedLocales | undefined
}

export const useLanguageSelect = (): UseLanguageSelect => {
  const { locale, handleLocaleChange } = useLocale()

  const setLang = (value: string) => {
    handleLocaleChange(value)
  }

  const { data, isLoading } = useCustomQueryWithParams(getSupportLocales, sharedApi.getSupportLocales)

  const selectedLocale = useMemo(() => {
    return getSelectedLocale(locale, data?.data) || defaultValue
  }, [data?.data, locale])

  const isMoreThanOneLang = !!data?.data?.length && data?.data?.length > 1

  return {
    setLang,
    isLoading,
    selectedLocale,
    isMoreThanOneLang,
    options: data?.data,
  }
}
