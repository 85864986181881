import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Suspense, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import cn from 'classnames'
import Sider from 'antd/es/layout/Sider'
import s from './Settings.module.css'
import Footer from '@/shared/components/Footer'
import { SettingsNavbar } from '@/pages/Settings/entities/SettingsNavbar'
import Header from '@/shared/components/Header'
import SpinLoadingOutlined from '@/shared/components/SpinLoadingOutlined'
import { routes } from '@/shared/constants/routes'

const wideRoutes = [routes.settings.widgetSettings.baseImageLibrary]

function SettingsLayout() {
  const location = useLocation()
  const isContentWide = useMemo(() => wideRoutes.some(route => location.pathname.includes(route)), [location.pathname])

  return (
    <Layout className={s.container}>
      <Header showReferral showSettings showDashboard showAvatar />
      <Layout className={s.contentWrapper}>
        <Sider className={s.sider} width={260}>
          <SettingsNavbar />
        </Sider>
        <Content className={cn(s.content, { [s.contentWide]: isContentWide, [s.contentNarrow]: !isContentWide })}>
          <Suspense fallback={<SpinLoadingOutlined />}>
            <Outlet />
          </Suspense>
        </Content>
        <div className={s.mock} />
      </Layout>
      <Footer />
    </Layout>
  )
}

export default SettingsLayout
