import { defineMessages } from 'react-intl'

export const lang = defineMessages({
  profileButton: {
    id: 'header.avatar.profile_button',
    defaultMessage: 'Go to Profile',
  },
  signOutButton: {
    id: 'header.avatar.sign_out_button',
    defaultMessage: 'Sign out',
  },
})
