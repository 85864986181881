export const getRedirectPathname = () => {
  return sessionStorage.getItem('authRedirectPathname')
}

export const setRedirectPathname = (pathname?: string) => {
  return sessionStorage.setItem('authRedirectPathname', pathname || '')
}

export const resetRedirectPathname = () => {
  return sessionStorage.removeItem('authRedirectPathname')
}
