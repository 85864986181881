import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import s from './Auth.module.css'
import Footer from '@/shared/components/Footer'
import Header from '@/shared/components/Header'
import SpinLoadingOutlined from '@/shared/components/SpinLoadingOutlined'
import { routes } from '@/shared/constants/routes.ts'

function AuthLayout() {
  return (
    <Layout className={s.container}>
      <Header logoLink={routes.auth.signIn} showLanguages />
      <Layout>
        <Content className={s.content}>
          <Suspense fallback={<SpinLoadingOutlined />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
      <Footer />
    </Layout>
  )
}

export default AuthLayout
