import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { logout } from '@/shared/utils/auth/logout.ts'

const PUBLIC_DOMAIN = import.meta.env.VITE_PUBLIC_DOMAIN
const WIDGET_DOMAIN = import.meta.env.VITE_WIDGET_DOMAIN
const NODE_DOMAIN = import.meta.env.VITE_NODE_DOMAIN

export const publicClient = axios.create({
  baseURL: PUBLIC_DOMAIN,
  timeout: 60000,
  withCredentials: true,
})

export const refreshAxiosClient = axios.create({
  baseURL: PUBLIC_DOMAIN,
  timeout: 60000,
  withCredentials: true,
})

export const widgetClient = axios.create({
  baseURL: WIDGET_DOMAIN,
  timeout: 60000,
  withCredentials: true,
})

export const nodeServerClient = axios.create({
  baseURL: NODE_DOMAIN,
  timeout: 60000,
})

function requestHandler(config: any) {
  const xTraceUUID = uuidv4()
  const spanUUID = uuidv4()

  config.headers['X-Trace'] = xTraceUUID
  config.headers['X-Span-Id'] = spanUUID

  return config
}

function mockRequestErrorHandler(error: any) {
  return Promise.reject(error)
}

function mockResponseHandler(config: any) {
  return config
}

let refreshPromise: undefined | Promise<void>

async function publicResponseErrorHandler(error: any) {
  if (error?.response?.status === 401) {
    try {
      refreshPromise =
        refreshPromise ||
        refreshAxiosClient.request({
          url: 'refresh',
          method: 'POST',
        })
      await refreshPromise
    } catch {
      logout(true)
      return Promise.reject(error)
    } finally {
      refreshPromise = undefined
    }

    return publicClient.request(error?.config)
  }

  return Promise.reject(error)
}

publicClient.interceptors.request.use(requestHandler, mockRequestErrorHandler)
publicClient.interceptors.response.use(mockResponseHandler, publicResponseErrorHandler)

widgetClient.interceptors.request.use(requestHandler, mockRequestErrorHandler)
