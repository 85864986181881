import { StorageService } from '@/entities/services/StorageService.ts'
import { IStorage } from '@/entities/localStorage/types.ts'

export class LocalStorageService<TValue> extends StorageService<TValue> implements IStorage<TValue> {
  constructor(key: string, defaultValue: TValue) {
    super(key, defaultValue, window.localStorage)

    if (typeof window !== 'undefined') {
      window.addEventListener('storage', event => {
        if (event.key === this.key) {
          this.dispatchChangeEvent()
        }
      })
    }
  }
}
