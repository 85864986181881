import React from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { ConfigProvider, Flex } from 'antd'
import { initSentry } from 'shared/utils/sentry.ts'
import * as Sentry from '@sentry/react'
import { PostHogProvider } from 'posthog-js/react'
import App from './App.tsx'
import './index.css'
import { LocaleProvider, useLocale } from '@/shared/contexts/LocaleContext'
import { themeConfig } from '@/shared/themeConfig.ts'
import { useCustomQueryWithParams } from '@/shared/hooks/useCustomQueryWithParams.ts'
import { getLocale } from '@/shared/api/constants.ts'
import { sharedApi } from '@/shared/api'
import { routes } from '@/shared/constants/routes.ts'
import SpinLoadingOutlined from '@/shared/components/SpinLoadingOutlined'
import { AudioPlayerProvider } from '@/shared/contexts/AudioPlayerContext'

const POSTHOG_HOST = import.meta.env.VITE_PUBLIC_POSTHOG_HOST
const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_API_KEY

const options = {
  api_host: POSTHOG_HOST,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        if (error?.response) {
          return false
        }

        if (failureCount >= 3) {
          return false
        }

        return true
      },
    },
  },
})

export function Main() {
  const { locale } = useLocale()
  const location = useLocation()
  const isObsWidget = location.pathname?.includes(routes.obs.base)

  const { data: messageResp, isLoading } = useCustomQueryWithParams(
    getLocale(locale),
    () => sharedApi.getLocale(locale),
    {
      keepPreviousData: true,
      enabled: !isObsWidget,
      retry: 0,
    }
  )

  if (isLoading && !isObsWidget) {
    return (
      <Flex style={{ height: '100vh' }} justify="center" align="center">
        <SpinLoadingOutlined />
      </Flex>
    )
  }

  return (
    <IntlProvider locale={locale} messages={messageResp?.data || {}}>
      <App />
    </IntlProvider>
  )
}

initSentry()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <PostHogProvider apiKey={POSTHOG_API_KEY} options={options}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <LocaleProvider>
              <ConfigProvider theme={themeConfig}>
                <AudioPlayerProvider>
                  <Main />
                </AudioPlayerProvider>
              </ConfigProvider>
            </LocaleProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)
