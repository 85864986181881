import { useEffect } from 'react'
import throttleByAnimationFrame from 'antd/es/_util/throttleByAnimationFrame'

export const useToastScroll = (marginTop: number) => {
  useEffect(() => {
    const root = document.documentElement

    root.style.setProperty('--toastify-toast-top', marginTop + 'px')

    const handleScroll = throttleByAnimationFrame(() => {
      const newTop = window.scrollY > marginTop ? 16 : marginTop - window.scrollY
      const currentTop = parseInt(getComputedStyle(root).getPropertyValue('--toastify-toast-top'), 10)

      if (currentTop !== newTop) {
        root.style.setProperty('--toastify-toast-top', newTop + 'px')
      }
    })

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [marginTop])
}
