import { AxiosError } from 'axios'
import { useQuery, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
}

export function useCustomQueryWithParams<TData>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, AxiosError>
): UseQueryResult<TData, AxiosError> {
  const { error, ...rest } = useQuery<TData, AxiosError>({ queryKey, queryFn, ...defaultQueryOptions, ...options })
  return { error, ...rest } as UseQueryResult<TData, AxiosError>
}
