import { AxiosResponse } from 'axios'
import { publicClient, nodeServerClient } from '@/entities/httpClient'
import { IConfirmTOTPConfig, ITotpDeletionParams } from '@/shared/api/types.ts'
import { EGenerateCodeReasons } from '@/pages/Auth/api/enums.ts'

const urls = {
  confirmation2fa: '/verification-codes/generate',
  totp: '/totps',
  totpConfirmation: '/totps/confirmation',
  deleteTotp: '/totps/deletion',
  supportLocales: '/locale/supported-locales',
  locale: (locale: string) => `/locale?locale=${locale}`,
}

export type SupportedLocales = Array<{ value: string; label: string }>

export const sharedApi = {
  async sendConfirmation2FaCode(purpose: EGenerateCodeReasons): Promise<AxiosResponse> {
    return publicClient.request({
      url: urls.confirmation2fa,
      method: 'POST',
      data: {
        purpose,
      },
    })
  },
  async createTOTP() {
    return publicClient.request({
      url: urls.totp,
      method: 'POST',
    })
  },
  async confirmTOTP(confirmConfig: IConfirmTOTPConfig) {
    return publicClient.request({
      url: urls.totpConfirmation,
      method: 'POST',
      data: confirmConfig,
    })
  },
  async deleteTOTP(params: ITotpDeletionParams) {
    return publicClient.request({
      url: urls.deleteTotp,
      method: 'PUT',
      data: params,
    })
  },
  async getSupportLocales(): Promise<AxiosResponse<SupportedLocales>> {
    return nodeServerClient.request({
      url: urls.supportLocales,
      method: 'GET',
    })
  },
  async getLocale(locale: string) {
    return nodeServerClient.request({
      url: urls.locale(locale),
      method: 'GET',
    })
  },
}
