import { theme, ThemeConfig } from 'antd'

export const themeConfig: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: 'rgba(121, 72, 255, 1)',
    colorBgSpotlight: 'var(--grey-800)',
  },
  components: {
    Radio: {
      buttonBg: 'var(--grey-900)',
      colorBorder: 'var(--grey-600)',
      colorPrimary: 'var(--accent-light)',
      colorPrimaryHover: 'var(--accent-light)',
      borderRadius: 8,
    },
    Button: {
      primaryColor: 'var(--grey-100)',
      colorPrimary: 'var(--accent-light)',
      colorPrimaryHover: 'var(--accent-light-hover)',
      colorPrimaryActive: 'var(--accent-dark)',
      defaultHoverBorderColor: 'var(--accent-light)',
      defaultBorderColor: 'var(--grey-600)',
      defaultHoverColor: 'var(--accent-light)',
      defaultShadow: 'none',
      primaryShadow: 'none',
      colorLink: 'var(--accent-light)',
      colorLinkActive: 'var(--accent-dark)',
      colorLinkHover: 'var(--accent-dark)',
      lineHeight: 20,
      fontWeight: 500,
    },
    Typography: {
      titleMarginTop: 0,
      titleMarginBottom: 0,
      colorLink: 'var(--grey-300)',
      colorLinkActive: 'var(--grey-500)',
      colorLinkHover: 'var(--grey-500)',
      colorText: 'var(--grey-300)',
    },
    Modal: {
      contentBg: 'var(--grey-900)',
      footerBg: 'var(--grey-900)',
      headerBg: 'var(--grey-900)',
      borderRadiusLG: 20,
    },
    Input: {
      addonBg: 'var(--bg-transparent)',
      colorText: 'var(--grey-100)',
      colorBorder: 'var(--grey-600)',
      colorBgContainer: 'var(--grey-900)',
      activeShadow: 'none',
      fontFamily: '"IBM Plex Sans", sans-serif',
      hoverBorderColor: 'var(--grey-500)',
      activeBorderColor: 'var(--accent-light)',
    },
    Select: {
      colorText: 'var(--grey-100)',
      colorBorder: 'var(--grey-600)',
      colorBgContainer: 'var(--grey-900)',
      fontFamily: '"IBM Plex Sans", sans-serif',
      boxShadowSecondary: 'none',
      colorPrimaryHover: 'var(--grey-600)',
      colorPrimary: 'var(--grey-600)',
      optionActiveBg: 'var(--accent-light-outline-hover)',
      optionSelectedBg: 'var(--accent-light)',
      controlOutline: 'var(--accent-light-outline)',
      colorBgElevated: 'var(--grey-600)',
    },
    InputNumber: {
      addonBg: 'var(--bg-transparent)',
      colorText: 'var(--grey-100)',
      colorBorder: 'var(--grey-600)',
      colorBgContainer: 'var(--grey-900)',
      hoverBorderColor: 'var(--grey-600)',
      activeBorderColor: 'var(--grey-600)',
      handleHoverColor: 'var(--accent-light)',
      hoverBg: 'var(--bg-transparent)',
      activeBg: 'var(--bg-transparent)',
      activeShadow: 'none',
    },
    Form: {
      itemMarginBottom: 0,
      verticalLabelPadding: '0 0 4px',
      fontFamily: '"IBM Plex Sans", sans-serif',
    },
    Menu: {
      itemBg: 'var(--transparent)',
      subMenuItemBg: 'var(--transparent)',
      itemActiveBg: 'var(--grey-1005)',
      itemHoverBg: 'var(--grey-1005)',
      itemColor: 'var(--grey-300)',
      itemSelectedBg: 'var(--grey-1005)',
      itemSelectedColor: 'var(--grey-100)',
      activeBarBorderWidth: 0,
      padding: 0,
      paddingXL: 0,
      paddingXS: 0,
      itemPaddingInline: 0,
    },
    Slider: {
      railBg: 'var(--grey-500)',
      railHoverBg: 'var(--grey-600)',
      trackBg: 'var(--accent-light)',
      dotBorderColor: 'var(--accent-light)',
      trackBgDisabled: 'var(--grey-500)',
      handleColorDisabled: 'var(--grey-500)',
      trackHoverBg: 'var(--accent-dark)',
      dotActiveBorderColor: 'var(--accent-dark)',
      handleActiveColor: 'var(--accent-light)',
      handleColor: 'var(--accent-light)',
      colorPrimaryBorderHover: 'var(--accent-dark)',
    },
    Checkbox: {
      colorBgContainer: 'var(--grey-900)',
      colorPrimary: 'var(--accent-light)',
      colorPrimaryHover: 'var(--accent-light)',
    },
    Switch: {
      colorPrimary: 'var(--accent-light)',
      colorPrimaryBorder: 'var(--accent-light)',
      colorPrimaryHover: 'var(--accent-light)',
    },
    Notification: {
      colorPrimary: 'var(--accent-light)',
      colorPrimaryBorderHover: 'var(--accent-dark)',
    },
    Divider: {
      marginLG: 0,
    },
    Layout: {
      siderBg: 'var(--transparent)',
      bodyBg: 'var(--transparent)',
    },
    Spin: {
      colorPrimary: 'var(--accent-light-hover)',
    },
    Upload: {
      colorPrimaryHover: 'var(--accent-light)',
      colorPrimaryBorder: 'var(--accent-light)',
    },
    Collapse: {
      headerPadding: '8px 0',
      contentPadding: 0,
    },
    DatePicker: {
      colorBorder: 'var(--grey-600)',
      colorBgContainer: 'var(--grey-900)',
    },
  },
}
